import * as ReactQuery from 'react-query';
import {
  UseQueryOptions,
  QueryKey,
  UseQueryResult,
  QueryFunction,
} from 'react-query';
import {useStoreActions} from '../store/hooks';

// copy the function signature from `react-query.useQuery`
export default function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<TData, TError> {
  const setAuthToken = useStoreActions(actions => actions.auth.setToken);

  const previousOnError = options?.onError;

  // inject onError query
  const onError = (err: TError) => {
    if (previousOnError) {
      previousOnError(err);
    }

    // if the error is 'jwt expired' then logout the user
    if (typeof err === 'string' && err === 'jwt expired') {
      setAuthToken(undefined);
    }
  };
  options = {
    ...options,
    onError,
  };

  return ReactQuery.useQuery(queryKey, queryFn, options);
}
