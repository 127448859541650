import { useLayoutEffect, useState } from 'react';
import { IQuestionType } from '../models/APIModels';
import { Location } from '../models/CardModels';
import { OVERLAY_EMOJIS } from '../utils/constants';

interface OverlayGradient {
  colors: string[]
}

export interface CardSwipeOverlayProps {
  question: IQuestionType
  location: Location
}


export const useOpacity = (location: Location, preventSwipeReader: () => string[]) => {
  const [emoji, setEmoji] = useState('');
  const [gradient, setGradient] = useState<OverlayGradient>({colors: ['transparent', 'transparent']});
  const [opacity, setOpacity] = useState(0);
  const preventSwipeDirections = preventSwipeReader();

  const calculateOpacity = (position: number) => {
    const opacityPercentage = (position - 20) / 100;
    const maxOpacity = Math.min(opacityPercentage, 1);
    return maxOpacity;
  };

  useLayoutEffect(() => {
    if (Math.abs(location.x) >= Math.abs(location.y) && location.x > 20 && !preventSwipeDirections.includes('right')) {
      setEmoji(OVERLAY_EMOJIS.OVERLAY_YES);
      const updatedOpacity = calculateOpacity(location.x);

      // Note: the last 2 digits of the hex color is used for the Alpha value
      // Here, we do updatedOpacity * 100 - 1, so the Alpha is never 100, max 99
      // when Alpha has 3 digits, it will be ignored.
      setGradient({colors: [`#0089DE${updatedOpacity * 100 - 1}`, `#40E8FF${updatedOpacity * 100 - 1}`]});
      setOpacity(updatedOpacity);

    } else if (Math.abs(location.x) >= Math.abs(location.y) && location.x < -20 && !preventSwipeDirections.includes('left')) {
      setEmoji(OVERLAY_EMOJIS.OVERLAY_NO);
      const updatedOpacity = calculateOpacity(location.x * -1);
      setGradient({colors: [`#FFB626${updatedOpacity * 100 - 1}`, `#FB0602${updatedOpacity * 100 - 1}`]});
      setOpacity(updatedOpacity);

    } else if (Math.abs(location.x) < Math.abs(location.y) && location.y > 20 && !preventSwipeDirections.includes('down')) {
      setEmoji(OVERLAY_EMOJIS.OVERLAY_SKIP);
      const updatedOpacity = calculateOpacity(location.y);
      setGradient({ colors: [`#F3EFEE${updatedOpacity * 100 - 1}`, `#FFFFFF${updatedOpacity * 100 - 1}`]});
      setOpacity(updatedOpacity);

    } else {
      setEmoji('');
      setGradient({colors: ['transparent', 'transparent']});
      setOpacity(0);
    }
  }, [location]);

  return { emoji, gradient, opacity };

};
