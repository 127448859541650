import React, { useEffect, useState } from 'react';
import { IPrizeType } from '../../models/APIModels';
import Spinner from '../Spinner/Spinner';
import FallbackComponent from '../Fallback/FallbackComponent';
import PrizeCountdown from '../PrizeStates/PrizeCountdown';
import { useOpenURL } from '../../hooks/useOpenURL';
import { usePrizeQuestions } from '../../hooks/usePrizes';
import './prize-deck-styles.css';
import PrizeOdds from '../PrizeOdds/PrizeOdds';
import { useStoreActions, useStoreState } from '../../store/hooks';
import PrizeQuestionsDeck from './PrizeQuestionsDeck';
import EmptyPrize from '../PrizeStates/EmptyPrize';
import { APP_STORE_URL } from '../../utils/constants';
import { PrizeAboutQuestionId, buildQuestion, PrizeLegalQuestionId, PrizeOnboardingQuestionId } from '../../models/Question';
import { buildMedia } from '../../models/Media';
import SweepstakesAgreementImage from '../../assets/sweepstakes_agreement.jpg';
import OnboardingInitialCard from '../../assets/onboarding-initial-card.jpg';

type PrizeDeckProps = {
  prize: IPrizeType;
}

const PrizeDeck = ({ prize }: PrizeDeckProps) => {
  const [currentPrize] = useState<IPrizeType>(prize);
  const { openURL } = useOpenURL();

  const setCurrentPrize = useStoreActions((actions) => actions.currentPrize.setCurrentPrize);
  const currentPrizeState = useStoreState((state) => state.currentPrize.currentPrize);

  useEffect(() => {
    if (currentPrize && currentPrize.id !== currentPrizeState?.prize?.id) {
      setCurrentPrize({
        ...currentPrizeState,
        prize: currentPrize,
      });
    }
  }, [currentPrize, currentPrizeState, setCurrentPrize]);

  const {
    isLoading, isError, error, data: questions, isRefetching,
  } = usePrizeQuestions(currentPrize);

  const handlePrizeEntry = (joined: boolean) => {
    setCurrentPrize({ ...currentPrizeState, entered: joined, skipped: !joined });
  };

  const deck = () => {
    if (!questions) return <></>;

    // build the about question
    const useAboutQuestionVideo = !!prize.aboutQuestion;
    const mediaUrl = useAboutQuestionVideo ? prize.aboutQuestion.media[0].url : prize.medium?.url;
    const aboutQuestionMedia = buildMedia({
      url: mediaUrl || '', // TODO: add placeholder url here (maybe the impulse logo?)
      type: useAboutQuestionVideo ? 'video' : 'image',
    });
    const aboutQuestion = buildQuestion({
      id: prize.aboutQuestion?.id ?? PrizeAboutQuestionId,
      description: '',
      media: [aboutQuestionMedia],
      isFreeResponse: false,
    });

    // build the onboarding question
    const onboardingInitialCard = buildQuestion({
      id: PrizeOnboardingQuestionId,
      description: '',
      media: [buildMedia({
        url: OnboardingInitialCard,
        type: 'image',
      })],
      isFreeResponse: false,
    });

    // build the legal question
    const legalQuestionMedia = buildMedia({
      url: SweepstakesAgreementImage,
      type: 'image',
    });
    const legalQuestion = buildQuestion({
      id: PrizeLegalQuestionId,
      description: '',
      media: [legalQuestionMedia],
      isFreeResponse: false,
    });

    return <PrizeQuestionsDeck prize={prize} questions={[onboardingInitialCard, aboutQuestion, legalQuestion, ...questions].reverse()} setPrizeEntry={(joined) => handlePrizeEntry(joined)} />;
  };

  const resetError = () => {
    console.log('error', error);
  };

  return (
    <div className="prize-deck-container">
      <div className="prize-deck-content">
        {currentPrizeState?.entered &&
          <div className="prize-odds-mini-preview">
            <PrizeOdds prize={prize} />
          </div>
        }
        {isRefetching && <Spinner color="white" visible={isRefetching} />}
        {isLoading && <Spinner color="white" visible={isLoading} />}
        {isError && error && <FallbackComponent error={error as Error} resetError={resetError} />}
        {questions && questions.length > 0 && currentPrizeState && !isRefetching && deck()}
        {currentPrizeState && currentPrizeState.skipped && <EmptyPrize />}
        {questions?.length === 0 && !isRefetching &&
          <PrizeCountdown
            prize={currentPrize}
            countdownTitle="winner picked in"
            countdownDate={currentPrize.endDate}
            actionTitle="download full app"
            handleActionPress={() => openURL(APP_STORE_URL.IMPULSE_APP_STORE_LINK)}
          />
        }
      </div>
    </div>
  );
};

export default PrizeDeck;
