import React, { useState } from 'react';
import TinderCard, { Direction } from 'react-tinder-card';
import { IMediaType, IPrizeType, IQuestionType } from '../../models/APIModels';
import { Location } from '../../models/CardModels';
import MediaCard from '../Card/MediaCard';
import ResponsePreviewComponent from './ResponsePreviewCard';

interface PrizeMediaCardProps {
  prize: IPrizeType;
  question: IQuestionType
  cardLocation: Location
  preventSwipeReader: () => string[]
  shouldMute: boolean
  isAboutQuestion: boolean
}

function PrizeMediaCard(props: PrizeMediaCardProps) {
  const { prize, question, cardLocation, preventSwipeReader, shouldMute, isAboutQuestion } = props;
  const [overrideMedia, setOverrideMedia] = useState<IMediaType|undefined>();

  const onError = () => {
    console.log('media failed to load, isAboutQuestion', isAboutQuestion);
    if (isAboutQuestion) {
      setOverrideMedia(prize.medium || undefined);
    } else {
      // TODO: handle non-about question error
    }
  };

  return (
    <MediaCard
      question={question}
      location={cardLocation}
      autoplay={true}
      preventSwipeReader={preventSwipeReader}
      shouldMute={shouldMute}
      onError={onError}
      overrideMedia={overrideMedia}
    />
  );
}

interface QuestionCardProps {
  question: IQuestionType;
  prize: IPrizeType;
  preventSwipeReader: () => string[];
  onSwipe: (direction: Direction) => void;
  responseMedia: IMediaType | undefined;
  shouldMute?: boolean;
  shouldBeVisible?: boolean
  isAboutPrizeQuestion: boolean;
}

export default function QuestionCard(props: QuestionCardProps) {
  const { question, preventSwipeReader, prize, onSwipe, shouldMute, shouldBeVisible, isAboutPrizeQuestion } = props;
  const { responseMedia } = props;

  const [cardLocation, setCardLocation] = useState<Location>({ x: 0, y: 0 });
  const handleCardLocationMove = (x: number, y: number) => {
    setCardLocation({ x, y });
  };

  const handleSwipe = (direction: Direction) => {
    console.log('[swiped] Swiped: ', direction, question);
    setCardLocation({ x: 0, y: 0 });

    // call "delegate"
    onSwipe(direction);
  };

  return (
    <div style={{ display: shouldBeVisible ? 'inline' : 'none', backgroundColor: 'blue' }} >
      <TinderCard
        className="question-card"
        swipeRequirementType="position"
        swipeThreshold={150}
        onCardLocationChange={(x, y) => handleCardLocationMove(x, y)}
        preventSwipe={preventSwipeReader}
        onSwipe={handleSwipe}
      >
        <div className="card-media-container">
          <div className="card-media">
            <PrizeMediaCard
              prize={prize}
              question={question}
              isAboutQuestion={isAboutPrizeQuestion}
              cardLocation={cardLocation}
              preventSwipeReader={preventSwipeReader}
              shouldMute={shouldMute || false}
            />
            <ResponsePreviewComponent
              question={question}
              preventSwipeReader={preventSwipeReader}
              response={responseMedia}
              location={cardLocation}
            />
          </div>
        </div>
      </TinderCard>
    </div>
  );
}
