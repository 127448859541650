import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { NativeBaseProvider } from 'native-base';
import StackWrapper from './screens/StackWrapper/StackWrapper';
import { useStoreActions, useStoreState } from './store/hooks';
import AuthScreen from './screens/Auth/AuthScreen';
import './global-styles.css';
import { useConfigs } from './hooks/useConfigs';
import { useIncomingURLHandler } from './hooks/useIncomingURLHandler';
import OnboardingTOSHandler from './screens/OnboardingTOSHandler/OnboardingTOSHandler';
import config from './config';
import SettingsModal from './components/Settings/SettingsModal';

export default function App () {
  const authToken = useStoreState((state) => state.auth.token);
  const removeCurrentPrize = useStoreActions((action) => action.currentPrize.removeCurrentPrize);
  const { unplugged } = useConfigs();
  const { getParams } = useIncomingURLHandler();
  const [incomingPrizeId, setIncomingPrizeId] = useState<string>();

  useEffect(() => {
    if (window.location.search) {
      const urlParams = getParams(window.location.search);
      if (urlParams.prizeId) {
        setIncomingPrizeId(urlParams.prizeId);
      }
    }
  }, [getParams]);

  const resetVotes = async () => {
    removeCurrentPrize(undefined);
  };

  return (
    <NativeBaseProvider>
      <Toaster />
      {/* AuthScreen handles setting the authToken */}
      {!authToken && <AuthScreen />}

      {/* post initial auth */}
      {authToken && <OnboardingTOSHandler />}
      {authToken && <StackWrapper unplugged={unplugged} prizeId={incomingPrizeId} />}
      {config.env === 'beta' &&
        <div className="settings-header">
          <SettingsModal resetVotesHandler={resetVotes} />
        </div>
      }
    </NativeBaseProvider>
  );
}
