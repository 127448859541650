import {Action, action} from 'easy-peasy';
import {IPrizeType} from '../models/APIModels';

export declare interface ICurrentPrizeType {
  id?: number;
  prize?: IPrizeType;
  entered?: boolean
  skipped?: boolean
}

export declare interface ICurrentPrizeStoreModel {
  currentPrize?: ICurrentPrizeType;
  setCurrentPrize: Action<ICurrentPrizeStoreModel, ICurrentPrizeType>;
  removeCurrentPrize: Action<ICurrentPrizeStoreModel, ICurrentPrizeType | undefined>;
}

const model: ICurrentPrizeStoreModel = {
  currentPrize: undefined,
  setCurrentPrize: action((state, payload) => {
    // set a random id to identify the prize
    payload.id = Math.floor(Math.random() * 10000);
    state.currentPrize = payload;
  }),
  removeCurrentPrize: action(state => {
    state.currentPrize = undefined;
  }),
};

export default model;
