import useQuery from './useQuery';
import { IPrizeType } from '../models/APIModels';
import { APIGetPrizes, APIGetPrize, APIGetPrizeQuestions, APIGetPrizeOdds, APIAddPrizeEntry } from '../services/api';
import { REACT_QUERY_CLIENT_KEYS } from '../utils/constants';

const usePrizes = () => {
  return useQuery(REACT_QUERY_CLIENT_KEYS.PRIZES, APIGetPrizes);
};

export const usePrizeWithId = (id: string) => {
  return useQuery('impulse-prize', () => APIGetPrize(id));
};

export const usePrizeQuestions = (prize: IPrizeType) => {
  return useQuery([REACT_QUERY_CLIENT_KEYS.PRIZE_QUESTIONS, prize], () => APIGetPrizeQuestions(prize));
};

export const usePrizeOdds = (prize: IPrizeType) => {
  return useQuery(REACT_QUERY_CLIENT_KEYS.PRIZE_ODDS, () => APIGetPrizeOdds(prize));
};

export const useAddPrizeEntry = (prize: IPrizeType) => {
  return useQuery(REACT_QUERY_CLIENT_KEYS.PRIZE_ENTRY, () => APIAddPrizeEntry(prize.id));
};

export default usePrizes;
